import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { supabase } from '../config/supabase'
import { Download } from 'lucide-react'

interface SignupFormData {
  email: string
  password: string
  confirmPassword: string
}

export const InviteSignup: React.FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [formData, setFormData] = useState<SignupFormData>({
    email: '',
    password: '',
    confirmPassword: ''
  })
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const [showAppDialog, setShowAppDialog] = useState(false)

  useEffect(() => {
    const email = searchParams.get('email')
    if (!email) {
      navigate('/')
      return
    }
    setFormData(prev => ({ ...prev, email }))
  }, [searchParams, navigate])

  const handleDownloadApp = () => {
    window.open('https://apps.apple.com/app/avenida/id6738580314', '_blank')
    setTimeout(() => navigate('/'), 500)
  }

  const handleSignup = async (e: React.FormEvent) => {
    e.preventDefault()
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match')
      return
    }

    try {
      const { error: signUpError } = await supabase.auth.signUp({
        email: formData.email,
        password: formData.password,
        options: {
          data: {
            invited: true
          }
        }
      })
      if (signUpError) throw signUpError
      setSuccess(true)
      setShowAppDialog(true)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred')
    }
  }

  return (
    <div className="w-full min-h-screen bg-[#F0EFE9]">
      <div className="max-w-2xl mx-auto px-6 py-8">
        <div className="flex justify-between items-center mb-12">
          <span className="text-red-900 font-serif text-2xl fixed left-8 top-8">Avenida</span>
        </div>
   
        <div className="space-y-8">
          <h1 className="text-4xl font-serif text-center">Create Account</h1>
          <div className="bg-[#F7F5EF] p-8 rounded">
            <form onSubmit={handleSignup} className="space-y-6">
              <div className="space-y-3">
                <label className="block text-sm font-light">Email</label>
                <input
                  type="email"
                  value={formData.email}
                  disabled
                  className="w-full p-3 font-light bg-white border border-gray-200 rounded-md"
                />
              </div>
   
              <div className="space-y-3">
                <label className="block text-sm font-light">Password</label>
                <input
                  type="password"
                  value={formData.password}
                  onChange={e => setFormData(prev => ({ ...prev, password: e.target.value }))}
                  className="w-full p-3 font-light bg-white border border-gray-200 rounded-md"
                />
              </div>
   
              <div className="space-y-3">
                <label className="block text-sm font-light">Confirm Password</label>
                <input
                  type="password"
                  value={formData.confirmPassword}
                  onChange={e => setFormData(prev => ({ ...prev, confirmPassword: e.target.value }))}
                  className="w-full p-3 font-light bg-white border border-gray-200 rounded-md"
                />
              </div>
   
              {error && <div className="text-red-900 font-light text-sm">{error}</div>}
              {success && (
                <div className="text-green-700 font-light text-sm">
                  Account created successfully!
                </div>
              )}
   
              <button 
                type="submit"
                className="w-full py-3 bg-black text-white rounded-full font-medium"
              >
                Create Account
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Custom Dialog without shadcn */}
      {showAppDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6 shadow-xl">
            <div className="text-center">
              <h2 className="text-lg font-semibold mb-2">Download Avenida App</h2>
              <div className="my-4 flex justify-center">
                <Download size={48} />
              </div>
              <p className="text-gray-600 mb-6">
                Get the full experience with our mobile app.
              </p>
            </div>
            
            <div className="space-y-3">
              <button
                onClick={handleDownloadApp}
                className="w-full py-3 bg-black text-white rounded-full font-medium hover:bg-gray-800 transition-colors"
              >
                Download App
              </button>
              <button
                onClick={() => navigate('/')}
                className="w-full py-3 bg-gray-100 text-black rounded-full font-medium hover:bg-gray-200 transition-colors"
              >
                Continue to Website
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default InviteSignup