import React, { useState } from "react";
import { ChevronLeft, X } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface PersonalInfo {
  firstName: string;
  lastName: string;
  identity: string;
  dob: string;
  email: string;
  location: string;
}

interface AdditionalInfo {
  jobTitle: string;
  interests: string[];
  otherClubs: string;
  clubNames?: string;
  instagramHandle: string;
  reference: string;
  contribution: string;
}

interface PaymentInfo {
  cardNumber: string;
  expiryDate: string;
  country: string;
}

type FormData = {
  personalInfo: PersonalInfo;
  additionalInfo: AdditionalInfo;
  paymentInfo: PaymentInfo;
};

interface MembershipFormProps {
  onClose: () => void;
  onSubmit: (data: FormData) => void;
}

const INITIAL_FORM_DATA: FormData = {
  personalInfo: {
    firstName: "",
    lastName: "",
    identity: "",
    dob: "",
    email: "",
    location: "",
  },
  additionalInfo: {
    jobTitle: "",
    interests: [],
    otherClubs: "",
    instagramHandle: "",
    reference: "",
    contribution: "",
  },
  paymentInfo: {
    cardNumber: "",
    expiryDate: "",
    country: "",
  },
};

const InterestPill = ({ interest, onRemove }: { interest: string; onRemove: () => void }) => (
  <div className="inline-flex items-center gap-1 px-3 py-1 bg-gray-100 rounded-full text-sm">
    {interest}
    <button onClick={onRemove} type="button" aria-label="Remove interest">
      <X className="w-3 h-3" />
    </button>
  </div>
);

const MembershipForm: React.FC<MembershipFormProps> = ({ onClose, onSubmit }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<FormData>(INITIAL_FORM_DATA);
  const [currentInterest, setCurrentInterest] = useState("");
  const [subscribeToUpdates, setSubscribeToUpdates] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleSubmit = async (data: FormData) => {
    setIsSubmitting(true);
    setError(null);
    
    const endpoint = 'https://ewossfduirntebnuyzxu.supabase.co/functions/v1/submit-application';
    
    try {
      const response = await window.fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${import.meta.env.VITE_SUPABASE_ANON_KEY}`
        },
        body: JSON.stringify({
          personalInfo: data.personalInfo,
          additionalInfo: data.additionalInfo,
          subscribedToUpdates: subscribeToUpdates
        })
      });

      const responseData = await response.json();
      
      if (!response.ok) {
        throw new Error(responseData.error || 'Submission failed');
      }
      
      onSubmit(data);
      navigate("/");
    } catch (error) {
      console.error('Submission error:', error);
      setError(error instanceof Error ? error.message : 'Failed to submit application');
    } finally {
      setIsSubmitting(false);
    }
  };

  const addInterest = (interest: string) => {
    if (interest.trim() && !formData.additionalInfo.interests.includes(interest.trim())) {
      updateAdditionalInfo("interests", [...formData.additionalInfo.interests, interest.trim()]);
      setCurrentInterest("");
    }
  };

  const removeInterest = (indexToRemove: number) => {
    updateAdditionalInfo(
      "interests",
      formData.additionalInfo.interests.filter((_, index) => index !== indexToRemove)
    );
  };

  const updatePersonalInfo = (field: keyof PersonalInfo, value: string) => {
    setFormData(prev => ({
      ...prev,
      personalInfo: { ...prev.personalInfo, [field]: value },
    }));
  };

  const updateAdditionalInfo = (field: keyof AdditionalInfo, value: string | string[]) => {
    setFormData(prev => ({
      ...prev,
      additionalInfo: { ...prev.additionalInfo, [field]: value },
    }));
  };

  const handleContinue = () => {
    if (step < 4) setStep(step + 1);
    else handleSubmit({ ...formData });
  };

  const handleBack = () => {
    if (step > 1) setStep(step - 1);
    else onClose();
  };

  return (
    <div className="fixed inset-0 bg-[#faf9f7] z-50 overflow-y-auto">
      <div className="max-w-2xl mx-auto px-6 py-8">
        <div className="flex justify-between items-center mb-12">
          <span className="text-red-800 font-serif text-2xl fixed left-8 top-8">Avenida</span>
        </div>

        <div className="mb-24">
          {step === 1 && (
            <div className="space-y-8">
              <h1 className="text-4xl font-serif text-center">Membership</h1>
              <p className="text-sm text-center text-gray-600 max-w-md mx-auto">
                Please fill out all the fields carefully in order for this application to be submitted to the Committee.
              </p>
            </div>
          )}

          {step === 2 && (
            <div className="space-y-6">
              <h1 className="text-4xl font-serif">Getting to know you</h1>
              <div className="space-y-4">
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  placeholder="Name"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.personalInfo.firstName}
                  onChange={(e) => updatePersonalInfo("firstName", e.target.value)}
                />
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  placeholder="Last name"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.personalInfo.lastName}
                  onChange={(e) => updatePersonalInfo("lastName", e.target.value)}
                />
                <select
                  id="identity"
                  name="identity"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.personalInfo.identity}
                  onChange={(e) => updatePersonalInfo("identity", e.target.value)}
                >
                  <option value="">How do you identify?</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                <input
                  id="dob"
                  name="dob"
                  type="date"
                  placeholder="DOB"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.personalInfo.dob}
                  onChange={(e) => updatePersonalInfo("dob", e.target.value)}
                />
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.personalInfo.email}
                  onChange={(e) => updatePersonalInfo("email", e.target.value)}
                />
                <input
                  id="location"
                  name="location"
                  type="text"
                  placeholder="Location"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.personalInfo.location}
                  onChange={(e) => updatePersonalInfo("location", e.target.value)}
                />
              </div>
            </div>
          )}

          {step === 3 && (
            <div className="space-y-6">
              <h1 className="text-4xl font-serif">Getting to know you</h1>
              <div className="space-y-4">
                <input
                  id="jobTitle"
                  name="jobTitle"
                  type="text"
                  placeholder="Job role / title"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.additionalInfo.jobTitle}
                  onChange={(e) => updateAdditionalInfo("jobTitle", e.target.value)}
                />
                <div className="space-y-2">
                  <div className="flex flex-wrap gap-2">
                    {formData.additionalInfo.interests.map((interest, index) => (
                      <InterestPill
                        key={index}
                        interest={interest}
                        onRemove={() => removeInterest(index)}
                      />
                    ))}
                  </div>
                  <input
                    id="interests"
                    name="interests"
                    type="text"
                    placeholder="Interests"
                    className="w-full p-3 border border-gray-200 rounded-md bg-white"
                    value={currentInterest}
                    onChange={(e) => setCurrentInterest(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        addInterest(currentInterest);
                      }
                    }}
                  />
                </div>

                <div className="space-y-3">
                  <select
                    id="otherClubs"
                    name="otherClubs"
                    className="w-full p-3 border border-gray-200 rounded-md bg-white"
                    value={formData.additionalInfo.otherClubs}
                    onChange={(e) => updateAdditionalInfo("otherClubs", e.target.value)}
                  >
                    <option value="">Are you a member of any other private clubs?</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>

                  {formData.additionalInfo.otherClubs === "yes" && (
                    <input
                      id="clubNames"
                      name="clubNames"
                      type="text"
                      placeholder="Which private clubs are you a member of?"
                      className="w-full p-3 border border-gray-200 rounded-md bg-white"
                      value={formData.additionalInfo.clubNames || ""}
                      onChange={(e) => updateAdditionalInfo("clubNames", e.target.value)}
                    />
                  )}
                </div>

                <input
                  id="instagramHandle"
                  name="instagramHandle"
                  type="text"
                  placeholder="What is your Instagram handle?"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.additionalInfo.instagramHandle}
                  onChange={(e) => updateAdditionalInfo("instagramHandle", e.target.value)}
                />
                <textarea
                  id="contribution"
                  name="contribution"
                  placeholder="Finally, what do you bring to Avenida?"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white h-32 resize-none"
                  value={formData.additionalInfo.contribution}
                  onChange={(e) => updateAdditionalInfo("contribution", e.target.value)}
                />
              </div>
            </div>
          )}

          {step === 4 && (
            <div className="space-y-6">
              <h1 className="text-4xl font-serif text-center">Thank you!</h1>
              {error ? (
                <p className="text-center text-red-600">{error}</p>
              ) : (
                <p className="text-center text-gray-600">We'll be in touch</p>
              )}
              <div className="flex items-center gap-2 justify-center mt-8">
                <input
                  type="checkbox"
                  id="subscribe"
                  name="subscribe"
                  checked={subscribeToUpdates}
                  onChange={(e) => setSubscribeToUpdates(e.target.checked)}
                  className="rounded border-gray-300"
                />
                <label htmlFor="subscribe" className="text-sm text-gray-600">
                  Subscribe to receive updates on special events
                </label>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="fixed bottom-0 left-0 right-0 bg-[#faf9f7] border-t border-gray-100">
        <div className="max-w-md mx-auto px-4 py-6">
          <div className="flex items-center justify-center gap-4 relative">
            <button 
              type="button"
              onClick={handleBack} 
              className="absolute left-0 flex items-center justify-center text-gray-600"
              aria-label="Back"
            >
              <ChevronLeft className="w-8 h-8" />
            </button>
            <div className="flex flex-col items-center">
              <button
                type="button"
                onClick={handleContinue}
                disabled={isSubmitting}
                className={`px-12 py-3 bg-black text-white rounded-full font-medium min-w-[160px] ${
                  isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {isSubmitting ? 'Submitting...' : step === 4 ? 'Complete' : 'Continue'}
              </button>
              <span className="text-sm text-gray-500 mt-2">{step}/4</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipForm;