import { X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Login from '../components/Login';
import { useState } from 'react';

const AdminDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<{ username: string; role: 'admin' | 'user' } | null>(null);
  const [view, setView] = useState<'dashboard' | 'invites'>('dashboard');
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [generatedLink, setGeneratedLink] = useState('');

  const handleLogout = () => {
    setUser(null);
    navigate('/admin');
  };

  const handleInvite = () => {
    const link = `${window.location.origin}/signup?email=${encodeURIComponent(email)}&fullName=${encodeURIComponent(fullName)}`;
    setGeneratedLink(link);
    console.log('Sending invite to:', email, 'for:', fullName);
  };

  const copyLink = () => {
    navigator.clipboard.writeText(generatedLink);
  };

  const renderInvites = () => (
    <div className="w-full min-h-screen bg-[#F7F5EF] overflow-y-auto">
      <div className="p-8 max-w-xl mx-auto">
        <div className="flex justify-between items-center mb-12">
          <div className="text-red-900 text-2xl font-serif">Avenida</div>
          <button onClick={() => setView('dashboard')} className="p-2" aria-label="Close invites">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="mt-12 space-y-8">
          <h2 className="text-3xl font-serif">Send Invites</h2>
          <div className="space-y-4">
            <input 
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder="Full Name"
              className="w-full border p-2 rounded font-light bg-white"
            />
            <input 
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email address"
              className="w-full border p-2 rounded font-light bg-white"
            />
          </div>
          <button 
            onClick={handleInvite}
            className="w-full bg-red-900 text-white px-4 py-2 rounded hover:bg-red-800 transition-colors"
          >
            Generate Invite Link
          </button>
          {generatedLink && (
            <div className="mt-4 space-y-2">
              <input 
                value={generatedLink}
                readOnly
                className="w-full p-2 bg-gray-50 rounded border font-light"
              />
              <button 
                onClick={copyLink}
                className="w-full bg-gray-200 px-4 py-2 rounded hover:bg-gray-300 transition-colors font-light"
              >
                Copy Link
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const renderDashboard = () => (
    <div className="w-full min-h-screen bg-[#F7F5EF] overflow-y-auto">
      <div className="p-8 max-w-xl mx-auto">
        <div className="flex justify-between items-center mb-12">
          <div className="text-red-900 text-2xl font-serif">Avenida</div>
          <button onClick={() => handleLogout()} className="p-2" aria-label="Logout">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="mt-12 space-y-6 text-gray-800">
          <h2 className="text-3xl font-serif mb-8">Admin Dashboard</h2>
          <button onClick={() => setView('invites')} className="text-red-900 hover:text-red-800">
            Send Invites
          </button>
        </div>
      </div>
    </div>
  );

  if (!user) {
    return <Login onLogin={(user) => setUser(user)} />;
  }

  if (view === 'invites') {
    return renderInvites();
  }

  return renderDashboard();
};

export default AdminDashboard;