import React, { useState } from 'react';
import { supabase } from '../config/supabase';

interface LoginProps {
  onLogin: (user: any) => void;
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [error, setError] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCredentials(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const { data, error: loginError } = await supabase.auth.signInWithPassword({
        email: credentials.email,
        password: credentials.password,
      });

      if (loginError) throw loginError;
      if (!data.user) throw new Error('User not found');

      onLogin(data.user);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Login failed');
    }
  };

  return (
    <div className="w-full min-h-screen bg-[#F7F5EF] overflow-y-auto">
      <div className="p-8 max-w-xl mx-auto">
        <h2 className="text-3xl font-serif mb-8">Login</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <label htmlFor="email" className="block text-xl font-serif">Email</label>
            <input
              id="email"
              name="email"
              type="email"
              value={credentials.email}
              onChange={handleInputChange}
              className="w-full border p-2 rounded font-light bg-white"
              autoComplete="off"
            />
          </div>
          <div className="space-y-2">
            <label htmlFor="password" className="block text-xl font-serif">Password</label>
            <input
              id="password"
              name="password"
              type="password"
              value={credentials.password}
              onChange={handleInputChange}
              className="w-full border p-2 rounded font-light bg-white"
              autoComplete="off"
            />
          </div>
          {error && <p className="text-red-500 text-sm font-light">{error}</p>}
          <button 
            type="submit"
            className="w-full bg-red-900 text-white px-4 py-2 rounded hover:bg-red-800 transition-colors"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
